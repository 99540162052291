.radio-label {
    position: relative;
    left: 3px;
}

.inputfile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile:focus + label {
    /* keyboard navigation */
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  .inputfile + label * {
    pointer-events: none;
  }


  .btn-file-upload{
    width: 187px;
    position:relative;
}

.btn-file-upload:after{
   content:  attr(value);
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;    
   width: 48%;
   background: #795548;
   color: white;
   border-radius: 2px;
   text-align: center;
   font-size: 12px;
   line-height: 2;
}

.custom-file-input~.custom-file-label::after {
    content: "לא נבחר קובץ";
  }

  .custom-file-label::after {
    content: "What's up?";
  }


  .km-button--primary {
    background-color: #5C5AA7;
    color: #FFFFFF;
}
.km-button {
    border-radius: 3px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: transparent;
    height: 36px;
    padding: 0px 16px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    min-width: 70px;
    transition: all 0.3s ease-out;
}
.km-btn-file {
    width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
  }
  .km-btn-file-label {
    line-height: 36px;
    cursor: pointer;
  }